import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import Index from '../views/index.vue'
import Login from '../views/login.vue'
import { Message } from "element-ui"

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'view-index',
        component: Index,
        children: [
            {
                path: '/',
                name: 'view-dashboard',
                component: () => import(/* webpackChunkName: 'view-dashboard' */ '../views/dashboard/index.vue')
            },
            {
                path: '/menu',
                name: 'view-menu',
                component: () => import(/* webpackChunkName: 'view-menu' */ '../views/menu/index.vue')
            },
            {
                path: '/admin',
                name: 'view-admin',
                component: () => import(/* webpackChunkName: 'view-admin' */ '../views/admin/index.vue')
            },
            {
                path: '/role',
                name: 'view-role',
                component: () => import(/* webpackChunkName: 'view-role' */ '../views/role/index.vue')
            },
            {
                path: '/member',
                name: 'view-member',
                component: () => import(/* webpackChunkName: 'view-member' */ '../views/member/index.vue')
            },
            {
                path: '/admin-log',
                name: 'view-admin-log',
                component: () => import(/* webpackChunkName: 'view-admin-log' */ '../views/admin-log/index.vue')
            },
            {
                path: '/open-door-records',
                name: 'view-open-door-records',
                component: () => import(/* webpackChunkName: 'view-open-door-records' */ '../views/open-door-records/index.vue')
            },
            {
                path: '/shop',
                name: 'view-shop',
                component: () => import(/* webpackChunkName: 'view-shop' */ '../views/shop/index.vue')
            },
            {
                path: '/article-type',
                name: 'view-article-type',
                component: () => import(/* webpackChunkName: 'view-article-type' */ '../views/article-type/index.vue')
            },
            {
                path: '/article',
                name: 'view-article',
                component: () => import(/* webpackChunkName: 'view-article' */ '../views/article/index.vue')
            },
            {
                path: '/system-config',
                name: 'view-system-config',
                component: () => import(/* webpackChunkName: 'view-system-config' */ '../views/system-config/index.vue')
            },
            {
                path: '/file',
                name: 'view-file',
                component: () => import(/* webpackChunkName: 'view-file' */ '../views/file/index.vue')
            },
            {
                path: '/archive',
                name: 'view-archive',
                component: () => import(/* webpackChunkName: 'view-archive' */ '../views/archive/index.vue')
            },
            {
                path: '/material',
                name: 'view-material',
                component: () => import(/* webpackChunkName: 'view-material' */ '../views/material/index.vue')
            },
            {
                path: '/reservation',
                name: 'view-reservation',
                component: () => import(/* webpackChunkName: 'view-reservation' */ '../views/reservation/index.vue')
            },
            {
                path: '/rfm',
                name: 'view-rfm',
                component: () => import(/* webpackChunkName: 'view-rfm' */ '../views/rfm/index.vue')
            },
            {
                path: '/rfm-setup',
                name: 'view-rfm-setup',
                component: () => import(/* webpackChunkName: 'view-rfm-setup' */ '../views/rfm-setup/index.vue')
            },
            {
                path: '/rfm-detail',
                name: 'view-rfm-detail',
                component: () => import(/* webpackChunkName: 'view-rfm-detail' */ '../views/rfm-detail/index.vue')
            },
            {
                path: '/issue-coupons',
                name: 'view-issue-coupons',
                component: () => import(/* webpackChunkName: 'view-issue-coupons' */ '../views/issue-coupons/index.vue')
            },
            {
                path: '/coupon',
                name: 'view-coupon',
                component: () => import(/* webpackChunkName: 'view-coupon' */ '../views/coupon/index.vue')
            },
            {
                path: '/goods',
                name: 'view-goods',
                component: () => import(/* webpackChunkName: 'view-goods' */ '../views/goods/index.vue')
            },
            {
                path: '/bi',
                name: 'view-bi',
                component: () => import(/* webpackChunkName: 'view-bi' */ '../views/bi/index.vue')
            },
            {
                path: '/goods/add',
                name: 'view-goods-add',
                component: () => import(/* webpackChunkName: 'view-goods-add' */ '../views/goods/add.vue')
            },{
                path: '/goods/edit',
                name: 'view-goods-edit',
                component: () => import(/* webpackChunkName: 'view-goods-add' */ '../views/goods/edit.vue')
            },
            {
                path: '/goods-type',
                name: 'view-goods-type',
                component: () => import(/* webpackChunkName: 'view-goods-type' */ '../views/goods-type/index.vue')
            },
			{
                path: '/order',
                name: 'view-order',
                component: () => import(/* webpackChunkName: 'view-order' */ '../views/order/index.vue')
            },{
                path:'/banner',
                name:'article-banner',
                component:()=>import('../views/banner/index.vue')
            },{
                path:'/assignment',
                name:'article-assignment',
                component:()=>import('../views/assignment/index.vue')
            },{
                path:'/pick',
                name:'article-pick',
                component:()=>import('../views/selfpick/index.vue')
            },{
                path:'/logistics',
                name:'article-logistics',
                component:()=>import('../views/logistics/index.vue')
            },{
                path:'/aftersales',
                name:'article-aftersales',
                component:()=>import('../views/aftersales/index.vue')
            },{
                path:'/agent',
                name:'article-agent',
                component:()=>import('../views/agent/index.vue')
            },{
                path:'/evaluate',
                name:'article-evaluate',
                component:()=>import('../views/evaluate/index.vue')
            },
            {
                path:'/recommend-list',
                name:'recommend-list',
                component:()=>import('../views/recommend/recommend-list/index.vue')
            },
            {
                path:'/recommend-cate',
                name:'recommend-cate',
                component:()=>import('../views/recommend/recommend-cate/index.vue')
            },
            {
                path:'/scenic-list',
                name:'scenic-list',
                component:()=>import('../views/scenic/scenic-list/index.vue')
            },
            {
                path:'/scenic-cate',
                name:'scenic-cate',
                component:()=>import('../views/scenic/scenic-cate/index.vue')
            },
            {
                path:'/scenic-add',
                name:'scenic-add',
                component:()=>import('../views/scenic/scenic-list/add.vue')
            },
            {
                path:'/scenic-order',
                name:'scenic-order',
                component:()=>import('../views/scenic/scenic-order/index.vue')
            },
            {
                path:'/scenic-refund',
                name:'scenic-refund',
                component:()=>import('../views/scenic/scenic-refund/index.vue')
            },
            {
                path:"/area-management",
                name:"area-management",
                component:()=>import('../views/area-management/index.vue')
            },
            {
                path:"/area-management-city",
                name:"area-management-city",
                component:()=>import('../views/area-management-city/index.vue')
            },
            {
                path:'/nurse-level',
                name:'nurse-level',
                component:()=>import('../views/nurse-level/index.vue')
            },
            {
                path:'/nurse-level-add',
                name:'nurse-level-add',
                component:()=>import('../views/nurse-level/add.vue')
            },
            {
                path:'/nurse-list',
                name:'nurse-list',
                component:()=>import('../views/nurse-list/index.vue')
            },
            {
                path: '/doctor',
                name: 'view-doctor',
                component: () => import(/* webpackChunkName: 'view-doctor' */ '../views/doctor/index.vue')
            },
            {
                path: "/hospital",
                name: "view-hospital",
                component: () => import(/* webpackChunkName: 'view-hospital' */ '../views/hospital/index.vue')
            },
            {
                path:"/route",
                name:"route",
                component:()=>import('../views/route/index.vue')
            },
            {
                path:'/question',
                name:'question',
                component:()=>import('../views/question/index.vue')
            },
            {
                path:'/health-article',
                name:'health-article',
                component:()=>import('../views/health-article/index.vue')
            },
            {
                path:'/help-center',
                name:'help-center',
                component:()=>import('../views/help-center/index.vue')
            },
            {
                path:'/route-order',
                name:'route-order',
                component:()=>import('../views/route-order/index.vue')
            },
            {
                path:'/feedback',
                name:'feedback',
                component:()=>import('../views/feedback/index.vue')
            },
            {
                path:'/rules',
                name:'rules',
                component:()=>import('../views/rules/index.vue')
            },
            {
                path:'/company',
                name:'company',
                component:()=>import('../views/company/index.vue')
            },
            {
                path:'/outknow',
                name:'outknow',
                component:()=>import('../views/outknow/index.vue')
            },
            {
                path:'/question-type',
                name:'question-type',
                component:()=>import('../views/question-type/index.vue')
            },
            {
                path:'/coursevideo',
                name:'coursevideo',
                component:()=>import('../views/coursevideo/index.vue')
            },
            {
                path:"/level-config",
                name:"level-config",
                component:()=>import('../views/level-config/index.vue')
            },
            {
                path:'/hotel',
                name:'hotel',
                component:()=>import('../views/hotel/index.vue')
            },
            {
                path:'/activity',
                name:'activity',
                component:()=>import('../views/activity/index.vue')
            },
            {
                path:'/active-cate',
                name:'active-cate',
                component:()=>import('../views/active-cate/index.vue')
            },
            {
                path:'/active-daily',
                name:'active-daily',
                component:()=>import('../views/active-daily/index.vue')
            }
        ]
    },
    {
        path: '/login',
        name: 'view-login',
        component: Login
    },
    {
        path: '*',
        name: 'view-404',
        component: () => import(/* webpackChunkName: 'view-404' */ '../views/404.vue')
    }
]

const router = new VueRouter({
    routes
})

router.beforeEach((to, from, next) => {
    if (to.path === "/rfm-detail") {
        if (store.state.buttons.findIndex(item => item.path === "rfm-detail") >= 0) {
            next();
        } else {
            Message.error("您没有该页面访问权限");
        }
    } else if (to.path === "/rfm-setup") {
        if (store.state.buttons.findIndex(item => item.path === "rfm-setup") >= 0) {
            next();
        } else {
            Message.error("您没有该页面访问权限");
        }
    } else {
        if (to.path !== '/login' && !store.state.token) {
            router.replace("/login");
        } else {
            next();
        }
    }
})

export default router
