import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        token: "",
        avatar: "",
        username: "",
        buttons: [],
        keepAlive: [],
        menu: []
    },
    getters: {
        banner_type() {
            //1首页2健康档案3健康教育
            return {
                1: {
                    label: '首页',
                    value: 1,
                    type: 'primary',
                    size: '(700*340)'
                },
                2: {
                    label: '健康档案',
                    value: 2,
                    type: 'warning',
                    size: '(750*270)'
                },
                3: {
                    label: '健康教育',
                    value: 3,
                    type: 'success',
                    size: '(700*300)'
                }
            }
        },
        is_recommend() {
            return {
                0: {
                    label: "否",
                    value: 0,
                    type: "info"
                },
                1: {
                    label: "是",
                    value: 1,
                    type: "success"
                }
            }
        },
        hotel_type() {
            return {
                1: {
                    label: "酒店",
                    value: 1,
                    type: "primary"
                },
                2: {
                    label: "民宿",
                    value: 2,
                    type: "success"
                }
            }
        },
        sex(){
            //1男 2女
            return {
                1: {
                    label: "男",
                    value: 1,
                    type: "primary"
                },
                2: {
                    label: "女",
                    value: 2,
                    type: "danger"
                }
            }
        },
        identity_color() {
        },
        is_tertiary() {
            //是否三甲 0否 1是
            return {
                0: {
                    label: "否",
                    value: 0,
                    type: "info"
                },
                1: {
                    label: "是",
                    value: 1,
                    type: "success"
                }
            }
        },
        is_health() {
            //是否医保 0否 1是
            return {
                0: {
                    label: "否",
                    value: 0,
                    type: "info"
                },
                1: {
                    label: "是",
                    value: 1,
                    type: "success"
                }
            }
        },
        consume_type_list() {
            return [
                {
                    label: "吃",
                    value: 1
                },
                {
                    label: "住",
                    value: 3
                },
                {
                    label: "行",
                    value: 4
                },
                {
                    label: "玩",
                    value: 6
                },
                {
                    label: "娱",
                    value: 7
                },
                {
                    label: '导游费',
                    value: 9
                },
                {
                    label: "保险费",
                    value: 10
                },
                {
                    label: "服务费",
                    value: 5
                },
            ]
        },
        consume_type() {
            //1吃2喝3住4行5服务费6玩7娱8充值
            return {
                1: {
                    value: 1,
                    label: "吃"
                },
                // 2: {
                //     value: 2,
                //     label: "喝"
                // },
                3: {
                    value: 3,
                    label: "住"
                },
                4: {
                    value: 4,
                    label: "行"
                },
                6: {
                    value: 6,
                    label: "玩"
                },
                7: {
                    value: 7,
                    label: "娱"
                },
                5: {
                    value: 5,
                    label: "服务费"
                },
                8: {
                    value: 8,
                    label: "充值"
                },
                9: {
                    value: 9,
                    label: "导游费"
                },
                10: {
                    value: 10,
                    label: "保险费"
                }
            }
        },
        route_order_status() {
            //0待支付1已支付2已取消6已完成
            return {
                0: {
                    label: "待支付",
                    value: 0,
                    type: "info"
                },
                1: {
                    label: "已支付",
                    value: 1,
                    type: "success"
                },
                2: {
                    label: "已取消",
                    value: 2,
                    type: "danger"
                },
                6: {
                    label: '已完成',
                    value: 6,
                    type: "success"
                }
            }
        }
    },
    mutations: {
        login(state, payload) {
            state.token = payload.token;
        },
        logout(state) {
            state.token = "";
        },
        setAdminInfo(state, payload) {
            state.avatar = payload.avatar;
            state.username = payload.username;
        },
        setButtons(state, payload) {
            state.buttons = payload;
        },
        setKeepAlive(state, alive) {
            state.keepAlive = alive
        },
        setMenu(state, menu) {
            state.menu = menu
        }
    },
    actions: {
        setKeepAlive(ctx, keepalice) {
            ctx.commit('setKeepAlive', keepalice)
        }
    },
    modules: {},
    plugins: [
        createPersistedState()
    ]
})
